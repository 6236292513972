import React from 'react';
import styled from '@emotion/styled';
import { color, breakpoints } from '../../../utils/style';
import LineQR from '../../Header/Line';
import ButtonLink from '../../shared/ButtonLink';

const LinePopupWrap = styled('div')`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  .line-col-left {
    flex-basis: 48%;
    padding: 10px 0;
  }
  p + p { margin-top: 10px; }
  .line-col-right {
    flex-basis: 50%;
    text-align: center;
    padding-top: 10px;
    .green-button {
      margin: 10px 0 0;
      padding: 10px 20px;
      font-size: 19px;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .line-col-left, .line-col-right {
      flex-basis: 100%;
    }
  }
  
`;



export default () => (
  <LinePopupWrap>
    <div className="line-col-left">
      <p>ผู้เล่นคาสิโน กรุณาเพิ่ม lnwasia เป็นเพื่อน เพื่อสร้างบัญชีของคุณให้สมบูรณ์</p>
      <p>ผู้เล่นสล็อตฟรี 15 ครั้ง กรุณาเช็คอีเมลของคุณ เพื่อทำการเปิดใช้งานบัญชี</p>
    </div>
    <div className="line-col-right">
      <LineQR to={process.env.LINE_ID} text="Line ID"/>
      <ButtonLink to={process.env.LINE_ID} external={true} ariaText="Line" target="_blank"
                  rel="noopener noreferrer"
                  className="button green-button"
                  text="@Inwasia" iconName="line" iconLeft={true} iconSize={26}/>
    </div>


  </LinePopupWrap>
);